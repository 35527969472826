<template>
 <div class="about-video">
     <div class="about-video__body">
       <div class="about-video__item">
         <div class="about-video__video">
           <iframe loading="lazy" width="100%" height="100%" src="https://www.youtube.com/embed/w2oeGfTJmMo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
         </div>
       </div>
       <div class="about-video__title">
         Посмотрите короткое видео о нашей компании
       </div>
     </div>
 </div>
</template>

<script>
export default {
  name: "AboutVideo"
}
</script>

<style lang="scss">
.about-video{
  margin-bottom: 80px;
  &__item{
    max-width: 895px;
    padding-bottom: 47%;
    border-radius: 16px;
    margin: 0 auto 30px auto;
    position: relative;
    overflow: hidden;
  }
  &__video{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }
  &__title{
    font-weight: 590;
    font-size: 26px;
    line-height: 36px;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 60px;
    &__title{
      display: none;
    }
  }
}
</style>
