<template>
<div class="reg">
  <div class="reg__text">
    <h2 class="reg__title">Оцените преимущества подбора квартиры лично</h2>
    <p class="reg__desc">Попробуйте наше новое приложение для поиска квартиры мечты</p>
  </div>
  <div class="reg__img">
    <img alt="регистрация" decoding="async" src="@/assets/images/reg-phone.png">
  </div>
  <div class="reg__background">
    <img alt="регистрация" decoding="async" src="@/assets/images/svg/reg-svg.svg">
  </div>
  <tk-button class="reg__btn" kind="reg-btn">Зарегистрироваться</tk-button>
</div>
</template>

<script>
import TkButton from '@/components/global/tkButton'
export default {
  name: 'AboutReg',
  components: { TkButton }
}
</script>

<style lang="scss">
.reg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 45px 55px;
  margin-bottom: 75px;
  background: #1AC386;
  border-radius: 16px;
  overflow-y: visible;
  &__background{
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    img{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: contain;
    }
  }
  //&::after {
  //  position: absolute;
  //  content: '';
  //  top: 0;
  //  left: 0;
  //  width: 100%;
  //  height: 100%;
  //  background-image: url('~@/assets/images/svg/reg-svg.svg');
  //  background-position: center;
  //  background-repeat: no-repeat;
  //  background-size: contain;
  //  border-radius: 16px;
  //}
  &__text {
    color: white;
    position: relative;
    z-index: 20;
  }

  &__title {
    margin: 0 0 12px 0;
    font-weight: 590;
    font-size: 26px;
    max-width: 400px;
    line-height: 36px;
  }

  &__desc {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    max-width: 300px;
  }
  &__btn{
    padding: 0 20px;
    font-weight: 500;
    color: #1AC386;
    position: relative;
    z-index: 10;
  }
  &__img {
    position: absolute;
    bottom: -4px;
    left: 42%;
    z-index: 20;
  }
  @media screen and (max-width: 991px){
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    padding: 28px 32px;
    &__background{
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      overflow: hidden;
      img{
        object-fit: cover;
        left: auto;
        right: -24%;
      }
    }
    &__img {
      position: absolute;
      right: 10%;
      left: auto;
    }
  }
  @media screen and (max-width: 769px) {
    padding: 20px;
    overflow: hidden;
    &__background{
      top: auto;
      bottom: 0;
      right: 0;
      height: 332px;
      width: 100%;
      overflow: hidden;
      img{
        object-fit: cover;
        left: auto;
        right: 0;
      }
    }
    &__title,
    &__desc{
      max-width: 100%;
    }
    &__btn{
      margin: 0 auto;
    }
    &__img {
      position: relative;
      width: 170px;
      height: 150px;
      z-index: 20;
      order: 3;
      bottom: -20px;
      left: calc(50% - 170px / 2);
      img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }
  }
}
</style>
