<template>
  <div class="benefit__accordion-item" :class="{ active: textShow }">
    <div class="benefit__accordion-item-header" @click="textShow = !textShow">
      <p>{{ title }}</p>
      <div class="benefit__accordion-item-icon"></div>
    </div>
    <div class="benefit__accordion-item-body">
      <p>{{ desc }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutBenefitItem',
  data () {
    return {
      textShow: false
    }
  },
  props: {
    title: String,
    desc: String
  }
}
</script>

<style lang="scss">
.benefit {
  &__accordion-item {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #EDEDEF;
    &.active {
      .benefit__accordion-item-body {
        transition: all 0.3s ease-out;
        padding: 4px 24px 28px 0;
        height: auto;
        visibility: visible;
        opacity: 1;
      }

      .benefit__accordion-item-icon {
        transform: rotate(45deg);
        transition: all 0.3s ease-out;
        background-color: #767582;

        &::before {
          transform: rotate(90deg);
          transition: all 0.3s ease-out;
          background-color: #767582;
        }
      }
    }
  }

  &__accordion-item-header {
    padding: 18px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    p {
      margin: 0 24px 0 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: var(--black-primary);
    }
  }

  &__accordion-item-icon {
    position: relative;
    width: 14px;
    height: 2px;
    background-color: #767582;
    transition: all 0.3s ease-out;

    &::before {
      position: absolute;
      content: '';
      width: 14px;
      height: 2px;
      background-color: #767582;
      transform: rotate(90deg);
      transition: all 0.3s ease-out;

    }
  }

  &__accordion-item-body {
    padding: 0;
    visibility: hidden;
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: all 0.3s ease-out;
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--black-secondary);
    }
  }
}

</style>
