<template>
  <div class="benefit">
    <div class="benefit__item">
      <div class="benefit__left">
        <h2 class="benefit__title">Выгода для хозяев</h2>
        <div class="benefit__accordion" v-for="item in benefitAccordionData1" :key="item">
          <AboutBenefitItem :title="item.title" :desc="item.desc"/>
        </div>
      </div>
      <div class="benefit__right">
        <img decoding="async" src="@/assets/images/about-benefits-img1.webp" srcset="@/assets/images/about-benefits-img1.webp 1x, @/assets/images/about-benefits-img12x.webp 2x" alt="Выгода для хозяев">
      </div>
    </div>
    <div class="benefit__item">
      <div class="benefit__left">
        <h2 class="benefit__title">Выгода для гостей</h2>
        <div class="benefit__accordion" v-for="item in benefitAccordionData2" :key="item">
          <AboutBenefitItem :title="item.title" :desc="item.desc"/>
        </div>
      </div>
      <div class="benefit__right">
        <img decoding="async" src="@/assets/images/about-benefits-img2.webp" srcset="@/assets/images/about-benefits-img2.webp 1x, @/assets/images/about-benefits-img22x.webp 2x" alt="Выгода для гостей">
      </div>
    </div>
  </div>
</template>

<script>
import AboutBenefitItem from "@/components/views/about/AboutBenefitItem";
export default {
  name: 'AboutBenefit',
  components: { AboutBenefitItem },
  data () {
    return {
      benefitAccordionData1: [
        {
          title: 'Дополнительный заработок',
          desc: 'В сервисе Теремок вы можете увеличить свой доход, частично или полностью сдавая жилье гостям.'
        },
        {
          title: 'Рейтинг жильцов',
          desc: 'Все гости проходят регистрацию на госуслугах и проверку паспортных данных.'
        },
        {
          title: 'Взаимные отзывы',
          desc: 'В нашем сервисе можно оставить отзыв не только о жилье и хозяине, но и о госте. Отзыв будет находиться в открытом доступе и влиять на рейтинг гостя, помогая другим пользователям сервиса.'
        },
        {
          title: 'Гибкие условия оплаты',
          desc: 'Вы можете самостоятельно установить цену на аренду жилья или предложить гостю взаимовыгодную сделку. Например, включающую помощью по хозяйству, которая компенсирует его проживание.'
        }
      ],
      benefitAccordionData2: [
        {
          title: 'Низкие цены',
          desc: 'Стоимость аренды жилья в сервисе Теремок значительно ниже, чем в гостиницах и квартирах посуточно, так как у вас есть возможность арендовать отдельное спальное место с самым необходимым набором удобств.'
        },
        {
          title: 'Большой выбор',
          desc: 'У нас вы найдете множество вариантов жилья и сможете выбрать то спальное место, которое вам понравилось.'
        },
        {
          title: 'Высокий уровень безопасности',
          desc: 'Мы проверяем паспортные данные и регистрацию на госуслугах всех арендодателей. Информация о подтверждении личности отражается в профиле хозяина жилья.'
        },
        {
          title: 'Гибкие условия',
          desc: 'Вы можете въезжать и выезжать в удобное для вас время. И договориться с хозяином жилья о дополнительных удобствах, не покидая сервис.'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.benefit {
  margin-bottom: 90px;
  gap: 75px;
  display: flex;
  flex-direction: column;
  @media (max-width: 479px) {
    margin-bottom: 60px;
  }
  &__item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    p{
      margin: 0;
    }
    @media (max-width: 1024px) {
      gap: 25px;
    }
    &:first-child {
      flex-direction: row;
      @media (max-width: 1024px) {
        flex-direction: column-reverse;
        align-items: center;
      }
    }

    &:last-child {
      flex-direction: row-reverse;

      @media (max-width: 1024px) {
        flex-direction: column-reverse;
        align-items: center;
      }
    }
  }

  &__title {
    margin: 0 0 24px 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--black-primary);
  }

  &__left {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    width: 100%;
    @media (max-width: 1024px) {
      max-width: 100%;
    }
  }

  &__accordion {
    display: flex;
    flex-direction: column;
  }

  &__right {
    position: relative;
    max-width: 410px;
    width: 100%;
    height: 332px;
    img{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
	    object-fit: cover;
    }
    @media screen and (max-width: 479px) {
      height: 200px;
      img{
        object-fit: contain;
      }
    }
  }
}
</style>
