<template>
  <div class="about-hero">
    <div class="about-hero__title">
      Объединяем хозяина жилья <br />
      и гостя на выгодных условиях
    </div>
    <tk-button
      @click="register"
      class="about-hero__btn"
      kind="main-white"
      v-if="!$store.getters.isAuth"
    >
      Присоединиться
    </tk-button>
		  <img decoding="async" alt="присоединиться" class="about-hero__img" src="~@/assets/images/about-main.webp">
  </div>
</template>

<script>
import TkButton from '@/components/global/tkButton'
import modals from "@/constants/modals";
export default {
  name: 'AboutHero',
  components: { TkButton },
  methods: {
    register() {
      this.$store.commit('showModal', {
        name: modals.REGISTRATION,
        props: null,
        isShow: true
      })
    },
  },
}
</script>

<style lang="scss">
.about-hero {
  margin: 45px 0 75px 0;
  padding: 70px 50px;
  border-radius: 28px;
	position: relative;
	overflow: hidden;
	&__img{
		width: 100%;
		position: absolute;
		height: 100%;
		top: 0;
		object-fit: cover;
		left: 0;  border-radius: 28px;

	}
  &__title {
    margin-bottom: 70px;
    font-weight: 700;
    font-size: 38px;
    line-height: 54px;
    color: #ffffff;
	  position: relative;
	  z-index: 2;
  }
  &__btn{
    padding: 0 20px;
    font-weight: 500;	  position: relative;
	  z-index: 2;
  }
  @media screen and (max-width: 769px) {
    padding: 55px 48px;
	  &__title{
		  margin-bottom: 40px;
	  }
  }
  @media screen and (max-width: 479px){
      padding: 16px;
      margin: 20px 0 48px 0;
      &__title{
      font-size: 23px;
      line-height: 36px;
    }
      &__btn{
        width: 100%;
      }
  }
}
</style>
