<template>
  <div class="about-rate">
    <div class="about-rate__text">
      <h2 class="about-rate__title">Оцените преимущества сами</h2>
      <p class="about-rate__desc">Это быстро и удобно</p>
      <tk-button
        @click="register"
        class="about-rate__btn"
        kind="main"
        v-if="!$store.getters.isAuth"
      >
        Зарегистрироваться
      </tk-button>
    </div>
    <div class="about-rate__img">
      <img decoding="async" src="@/assets/images/about-reg-img.webp" srcset="@/assets/images/about-reg-img.webp 1x, @/assets/images/about-reg-img2x.webp 2x" alt="наши преимущества">
    </div>
  </div>
</template>

<script>
import TkButton from '@/components/global/tkButton'
import modals from "@/constants/modals";
export default {
  name: 'AboutRate',
  components: { TkButton },
  methods: {
    register() {
      this.$store.commit('showModal', {
        name: modals.REGISTRATION,
        props: null,
        isShow: true
      })
    },
  },
}
</script>

<style lang="scss">
.about-rate {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 55px 0px 32px;
  margin-bottom: 75px;
  background: #F9F9FA;
  border-radius: 16px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
    gap: 25px;
	  padding: 10px 32px 20px 32px;
  }

  &::before {
    position: absolute;
    content: '';
    top: 65px;
    left: 50%;
    width: 20px;
    height: 20px;
    background-image: url('~@/assets/images/svg/about-icon-plus.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 768px) {
      content: none;
    }
  }

  &::after {
    position: absolute;
    content: '';
    bottom: 20px;
    right: 30%;
    width: 20px;
    height: 20px;
    background-image: url('~@/assets/images/svg/about-icon-plus.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transform: rotate(-20deg);
    @media (max-width: 768px) {
      content: none;
    }
  }

  &__text {
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__title {
    margin: 0 0 12px 0;
    font-weight: 590;
    font-size: 24px;
    line-height: 29px;
    color: #16142B;
    @media (max-width: 768px) {
      text-align: center;
    }
  }

  &__desc {
    margin: 0 0 28px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #626170;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  &__btn{
    padding: 8px 16px;
  }
  &__img {
	  position: relative;
	  width: 192px;
	  height: 187px;
	  img{
		  position: absolute;
		  width: 100%;
		  height: 100%;
		  object-fit: cover;
		  top: 0;
		  left: 0;
	  }
  }
}
</style>
