<template>
  <section class="about-gallery">
    <Gallery
        class="guest-page__item"
        title="Полезные статьи"
        card="ArticleCard"
        type-card="small"
        :carousel="carouselArticlesSettings"
        :content="articles.items"
        :meta="articles.meta"
        @more="get($event)"
    />
  </section>
</template>

<script>
import {defineAsyncComponent} from "vue";
import articlesType from "@/constants/articlesType";

export default {
  name: "AboutGallery",
  components: {
    Gallery: defineAsyncComponent(() =>
        import('@/components/views/home/Gallery')
    ),
  },
  data() {
    return {
      articles: {
        items: [],
        meta: {},

        getAbortController: null,
      },
    }
  },
  computed: {
    carouselArticlesSettings() {
      return {
        settings: { itemsToShow: 3, snapAlign: 'start', autoplay: 5000 },
        breakpoints: { 320: { itemsToShow: 1, snapAlign: 'start' }, 700: { itemsToShow: 2, snapAlign: 'start' }, 1100: { itemsToShow: 3, snapAlign: 'start' } }
      }
    },
  },
  methods: {
    async get(isUpdate = false) {
      if (this.getAbortController) return

      let page = 1

      if (isUpdate) {
        const { meta } = this.articles

        if (meta.current_page < meta.last_page) {
          page = meta.current_page + 1
        } else {
          return // заканчиваем загружать, так как страницы закончились
        }
      }

      this.getAbortController = new AbortController()

      const result = await this.$api.articles.getAll({
        type: articlesType.GUEST_ARTICLES,
        page,
        limit: 4,
        descriptionField: 'description'
      }, { controller: this.getAbortController })

      if (result?.status) {
        this.articles = {
          items: [...this.articles.items, ...result.response.items],
          meta: result.response.meta
        }
      }

      this.getAbortController = null
    }
  },
  beforeMount() {
    this.get()
  },
  beforeUnmount() {
    this.getAbortController?.abort()
  }
}
</script>

<style lang="sass">
.about-gallery
  margin-bottom: 55px
</style>
