<template>
  <div class="about-why">
    <h2 class="about-why__title">Почему выбирают нас</h2>
    <div class="about-why__items-wrap">
      <div class="about-why__item" v-for="item in aboutWhyData" :key="item">
        <div class="about-why__item-icon">
          <tk-svg :type="item.type" />
        </div>
        <p class="about-why__item-title">{{ item.title }}</p>
        <p class="about-why__item-desc">{{ item.desc }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import TkSvg from "@/components/global/tkSvg";
const aboutWhyData = [
  {
    title: 'Доступный ночлег для всех',
    desc: 'Гостям - место для ночлега с комфортными условиями по выгодной цене',
    type: 'why-smile'
  },
  {
    title: 'Проверенные гости',
    desc: 'Владельцам жилья - надежных гостей и дополнительный доход при сдаче жилья',
    type: 'why-approved'
  },
  {
    title: 'Полезные знакомства',
    desc: 'Помогаем обрести новые знакомства и хорошо провести время в приятной обстановке',
    type: 'why-people'
  },
  {
    title: 'Жильё по всей Росии',
    desc: 'Делаем проживание в любом месте страны комфортнее, доступнее и проще',
    type: 'why-plane'
  }
]
export default {
  name: 'AboutWhy',
  components: {TkSvg},
  setup (props) {
    return {
      aboutWhyData
    }
  }
}
</script>

<style lang="scss">
.about-why {
  margin-bottom: 75px;
  &__title {
    margin-bottom: 40px;
    font-weight: 590;
    font-size: 26px;
    line-height: 36px;
    color: #16142b;
  }

  &__items-wrap {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(282px, 1fr));
    grid-gap: 24px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    border: 1px solid #EDEDEF;
    border-radius: 16px;
  }
  &__item-icon {
    margin-bottom: 30px;
    width: 40px;
    height: 40px;
    background: rgba(245, 245, 246, 0.8);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__item-title {
    margin: 0 0 12px 0;
    font-weight: 590;
    font-size: 16px;
    line-height: 24px;
    color: #16142B;
  }

  &__item-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #626170;
    margin: 0;
  }
  @media screen and (max-width: 991px){
    &__title {
      margin-bottom: 28px;
    }
    &__items-wrap{
      grid-gap: 15px;
    }
  }
  @media screen and (max-width: 479px){

  }
}
</style>
