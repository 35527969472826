<template>
 <div class="about">
   <div class="about__container container">
     <AboutHero />
     <AboutWhy />
     <AboutBenefit />
     <AboutVideo />
     <AboutReg v-show="false" />
     <AboutRate />
     <AboutGallery />
   </div>
 </div>
</template>

<script>
import AboutHero from "@/components/views/about/AboutHero";
import AboutWhy from "@/components/views/about/AboutWhy";
import AboutBenefit from "@/components/views/about/AboutBenefit";
import AboutReg from "@/components/views/about/AboutReg";
import AboutGallery from "@/components/views/about/AboutGallery";
import AboutVideo from "@/components/views/about/AboutVideo";
import AboutRate from "@/components/views/about/AboutRate";
export default {
  name: "About",
  components: {AboutRate, AboutVideo, AboutGallery, AboutReg, AboutBenefit, AboutWhy, AboutHero}
}
</script>

<style lang="scss">

</style>
